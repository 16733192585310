// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-careers-js": () => import("./../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-home-js": () => import("./../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-js": () => import("./../src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-news-lawadvisor-launches-new-solutions-in-collaboration-with-barclays-js": () => import("./../src/pages/news/lawadvisor-launches-new-solutions-in-collaboration-with-barclays.js" /* webpackChunkName: "component---src-pages-news-lawadvisor-launches-new-solutions-in-collaboration-with-barclays-js" */),
  "component---src-pages-news-lawadvisor-secures-backing-from-legal-heavyweights-with-seed-round-js": () => import("./../src/pages/news/lawadvisor-secures-backing-from-legal-heavyweights-with-seed-round.js" /* webpackChunkName: "component---src-pages-news-lawadvisor-secures-backing-from-legal-heavyweights-with-seed-round-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-steering-group-js": () => import("./../src/pages/steering-group.js" /* webpackChunkName: "component---src-pages-steering-group-js" */),
  "component---src-pages-work-with-us-js": () => import("./../src/pages/work-with-us.js" /* webpackChunkName: "component---src-pages-work-with-us-js" */),
  "component---src-templates-episode-template-js": () => import("./../src/templates/episodeTemplate.js" /* webpackChunkName: "component---src-templates-episode-template-js" */)
}

